/* @import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@400;0,500;0,600;0&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@400;0,500;0,600;0,700;0&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Fira Sans", sans-serif; */
  font-family: "Play", sans-serif;
  font-weight: 500;
  color: #fff;
}

html {
  scroll-behavior: smooth;
  --primary: #07bc0c;
}

body {
  background: #161628;
}

.Toastify * {
  color: #1e1e1e;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 100px;
}

.main-wrapper {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  background-size: cover;
  background-position: center;
  background: #161628;
}

a {
  text-decoration: none;
  color: #fff;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
}

.header .logo img {
  width: 150px;
}

.header .navs {
  display: flex;
  align-items: center;
}

.header .navs .nav {
  font-size: 14px;
  margin-right: 40px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #ffffffb2;
  padding-bottom: 5px;
  position: relative;
}

.header .navs .nav.open {
  color: #fff;
  border-bottom: 2px solid var(--primary);
}

.header .navs .nav:hover {
  color: #fff;
}

.header .navs .nav::after {
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 2px solid var(--primary);
  content: "";
  transition: all 300ms linear;
}

.header .navs .nav:hover::after {
  width: 100%;
}

.header .navs .login-btn,
.mobile-nav .login-btn {
  color: #fff;
  background: var(--primary);
  font-size: 14px;
  height: 35px;
  width: 100px;
  border-radius: 100px;
  display: grid;
  place-items: center;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: 600;
}

.main-wrapper .content {
  width: 100%;
}

.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.home .h1 {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 2px;
  text-align: center;
  padding: 0 10px;
}

.home .h1 span {
  font-weight: 600;
  color: var(--primary);
  font-size: 58px;
  display: block;
  margin-bottom: 7px;
  text-transform: uppercase;
}

.home p {
  max-width: 1200px;
  font-weight: 500;
  margin-top: 30px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffffb2;
  /* font-family: "Poppins", sans-serif; */
  padding: 0 10px;
  margin-bottom: 40px;
}

.search-bar {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 20px;
}

.search-bar input {
  font-size: 18px;
  max-width: 900px;
  height: 65px;
  padding: 0 40px;
  border-radius: 10px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.07);
  letter-spacing: 1px;
  /* font-family: "Poppins", sans-serif; */
  width: 100%;
  /* border: 1px solid rgba(20, 148, 20, 0.5); */
  border-radius: 100px;
}

.search-bar input:focus {
  border: 1px solid var(--primary);
}

.search-bar input::placeholder {
  color: #ffffffb2;
}

.search-bar .search-btn {
  font-weight: 600;
  font-size: 18px;
  background: var(--primary);
  height: 65px;
  width: 150px;
  border-radius: 10px;
  margin-left: 15px;
  display: grid;
  place-items: center;
  letter-spacing: 1px;
  /* font-family: "Poppins", sans-serif; */
  border-radius: 100px;
}

.search .search-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* flex-wrap: wrap; */
  flex-direction: column;
}

.search .card {
  background: rgba(189, 195, 199, 0.08);
  margin: 10px 0;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 300px; */
  overflow: hidden;
  max-width: 1000px;
}

.search .card .row {
  display: flex;
  margin: 7.5px 0;
  justify-content: space-between;
  letter-spacing: 1px;
  font-size: 14px;
}

.search .card .row .col-1 {
  margin-right: 10px;
  color: #f1c40f;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.search .card .row .col-1 i {
  margin-right: 5px;
  color: var(--primary);
}

.search .card .row .col-2 {
  color: #fff;
}

.search .card .source {
  font-size: 18px;
  margin-bottom: 15px;
  color: #bdc3c7;
  letter-spacing: 1.5px;
  border-bottom: 1px solid var(--primary);
  padding-bottom: 10px;
}

footer {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

footer span {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.7);
}

.mobile-header {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mobile-header .logo {
  height: 50px;
}

.mobile-header .menu-btn {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
}

.mobile-header .menu-btn img {
  width: 35px;
}

.clickable:hover {
  opacity: 0.7 !important;
}

.mobile-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.3);
  flex-wrap: nowrap;
  height: 0;
  overflow: hidden;
  transition: height 300ms linear;
}

.mobile-nav a {
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  text-align: center;
  color: #f3f3f3;
  letter-spacing: 0.5px;
}

.mobile-nav .login-btn {
  margin-top: 15px;
  font-size: 14px;
}

.about-us .main-wrapper .content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.about-us h1 {
  margin-bottom: 30px;
  color: var(--primary);
  font-size: 30px;
  text-align: center;
}

.about-us p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 27px;
  text-align: justify;
  max-width: 900px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.7);
}

.about-us p.p {
  color: #fff;
  text-align: center;
  margin-top: 10px;
}

.contact-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 0 20px;
  flex-direction: column;
}

.contact-wrapper h1 {
  margin-bottom: 20px;
  color: var(--primary);
  font-size: 34px;
}

.contact-wrapper form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-wrapper form input,
.contact-wrapper form textarea {
  margin: 10px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  font-size: 16px;
  padding: 15px 20px;
  width: 720px;
  letter-spacing: 1px;
  border-radius: 6px;
  max-width: 100%;
}

.contact-wrapper form input:focus,
.contact-wrapper form textarea:focus {
  border: 0.5px solid var(--primary);
}

.contact-wrapper form input::placeholder,
.contact-wrapper form textarea::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.contact-wrapper form section input {
  width: 350px;
}

.contact-wrapper form button {
  background: var(--primary);
  margin-top: 20px;
  cursor: pointer;
  width: 100%;
  max-width: 720px;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 15px;
  margin-bottom: 10px;
}

.contact-wrapper form button:hover {
  opacity: 0.7;
}

.contact-wrapper form textarea {
  resize: none;
  height: 130px;
}

.contact-wrapper P {
  width: 100%;
  max-width: 720px;
  font-style: 15px;
  margin-bottom: 20px;
  line-height: 27px;
  text-align: justify;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.7);
}

.contact-wrapper form {
  margin-bottom: 10px;
}

.signup {
  background: #161628;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup main {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.08);
  padding: 30px 20px;
  max-width: calc(100% - 40px);
  border-radius: 20px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.signup main input {
  border: none;
  outline: none;
  background: #161628;
  padding: 20px;
  font-size: 15px;
  margin: 7.5px 0;
  width: 400px;
  letter-spacing: 0.5px;
  max-width: 100%;
  border-radius: 100px;
  border: 1px solid #161628;
}

.signup main input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.signup main input:focus {
  border: 1px solid var(--primary);
}

.signup main .btn {
  background: var(--primary);
  font-size: 15px;
  font-weight: bold;
  padding: 15px;
  border-radius: 100px;
  margin-top: 15px;
  letter-spacing: 1px;
  width: 200px;
  text-align: center;
}

.signup main h1 {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  letter-spacing: 0.5px;
  color: #bdc3c7;
}

.signup main .info {
  font-size: 15px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
  letter-spacing: 1px;
}

.signup main .info a {
  color: var(--primary);
  font-weight: bold;
}

.signup .err {
  font-size: 13px;
  margin-bottom: 7.5px;
  margin-left: 20px;
  color: #f05c4c;
  letter-spacing: 0.5px;
  margin-top: -3px;
  width: 100%;
  line-height: 23px;
}

.loader-cont {
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.credits .credits-wrapper {
  padding: 0 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.credits .my-credits {
  background: #161628;
  max-width: 100%;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  display: flex;
  flex-direction: column;
  font-size: 40px;
  letter-spacing: 1px;
}

.credits .my-credits span {
  font-size: 20px;
  color: var(--primary);
}

.credits h2 {
  margin-top: 30px;
  font-size: 26px;
  letter-spacing: 1px;
  color: #bdc3c7;
}

.credits input {
  font-size: 18px;
  margin-top: 15px;
  background: #161628;
  border: none;
  outline: none;
  padding: 15px 20px;
  width: 500px;
  letter-spacing: 1px;
  border-radius: 5px;
  max-width: 100%;
}

.credits input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.credits .btn {
  font-size: 16px;
  margin-top: 20px;
  padding: 10px 20px;
  background: var(--primary);
  width: 500px;
  text-align: center;
  border-radius: 5px;
  letter-spacing: 1px;
  max-width: 100%;
}

.credits main {
  max-width: 100%;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.credits .prices {
  margin-top: 20px;
  display: flex;
  width: 500px;
  flex-wrap: wrap;
  max-width: 100%;
}

.credits .prices .price {
  background: #161628;
  font-size: 18px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  width: 100px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

/* media querys */

@media (max-width: 1120px) {
  .search-bar {
    flex-direction: column;
    align-items: center;
  }

  .search-bar input {
    font-size: 14px;
  }

  .search-bar .search-btn {
    width: 100%;
    max-width: 900px;
    margin-left: 0;
    margin-top: 20px;
    font-size: 14px;
  }

  .home p {
    font-size: 12px;
    line-height: 20px;
    margin: 20px 0;
  }

  .home .h1 {
    font-size: 14px;
    line-height: 20px;
  }

  .home .h1 span {
    white-space: nowrap;
    font-size: 26px;
    margin-bottom: 10px;
  }

  footer {
    padding: 0 10px;
    margin-top: 20px;
  }

  footer span {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 785px) {
  .contact-wrapper form section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .contact-wrapper form section input {
    width: 720px;
    max-width: 100%;
    margin: 10px 0;
  }
}
